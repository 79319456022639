import React from 'react';

export default function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      enableBackground="new 0 0 64 64"
      style={{ height: '1em', verticalAlign: 'bottom' }}
    >
      <path
        d="M5.9,62c-3.3,0-4.8-2.4-3.3-5.3L29.3,4.2c1.5-2.9,3.9-2.9,5.4,0l26.7,52.5c1.5,2.9,0,5.3-3.3,5.3H5.9z"
        fill="#ffce31"
      />
      <g fill="#231f20">
        <path d="m27.8 23.6l2.8 18.5c.3 1.8 2.6 1.8 2.9 0l2.7-18.5c.5-7.2-8.9-7.2-8.4 0" />
        <ellipse cx="32" cy="49.6" rx="4.2" ry="4.2" />
      </g>
    </svg>
  );
}
