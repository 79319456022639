import styled from 'styled-components';

export default styled.ul`
  margin: 1em;

  li {
    display: inline;
    margin: 0.5em;
  }
`;
